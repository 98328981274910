<template>
  <div class="commission_wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      岗位佣金管理
    </div>
    <div class="content">
      <div class="top">
        <div class="item">
          <div class="label">项目部:</div>
          <div class="input__content">
            <a-select
              v-model="projectDepartmentId"
              :options="Departmentlist"
              placeholder="请选择项目部"
              @change="changeDepartment"
              style="width: 100%"
              allowClear
            ></a-select>
          </div>
        </div>
        <div class="item">
          <div class="label">项目名称:</div>
          <div class="input__content">
            <a-select
              v-model="projectId"
              :options="Projectlist"
              placeholder="请选择项目名称"
              @change="changeProjectlist"
              style="width: 100%"
              allowClear
            ></a-select>
          </div>
        </div>
        <div class="item">
          <div class="label">工种/专业:</div>
          <div class="input__content">
            <a-select
              v-model="positionId"
              :options="Positionlist"
              placeholder="请选择工种/专业"
              @change="changePosition"
              style="width: 100%"
              allowClear
            ></a-select>
          </div>
        </div>
        <div class="item">
          <div class="label">员工姓名:</div>
          <div class="input__content">
            <a-input
              v-model="realName"
              allowClear
              placeholder="员工姓名"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="search_box">
        <div class="item__content">
          <div class="label">联系电话:</div>
          <div class="input__content">
            <a-input
              v-model="phone"
              allowClear
              placeholder="联系电话"
              style="width: 100%"
            />
          </div>
        </div>
        <div class="item last_item">
          <a-button type @click="handleReset">重置</a-button>
          <a-button
            type="primary"
            :loading="loading"
            @click="handleGetData"
          >查询</a-button
          >
        </div>
      </div>
      <div class="btn_box">
        <div class="left">
          <a-button
            type="primary"
            :disabled="!selectedRows.length"
            @click="handleAddAll"
          >批量上传</a-button
          >
        </div>
        <div class="right">
          <a-button type="link" @click="handleDownLoad">导出表格模板</a-button>
          <a-upload name="file" :showUploadList="false" :customRequest="customRequest">
            <a-button type="link" style="color: green">导入薪资数据表</a-button>
          </a-upload>
        </div>
      </div>
      <div class>
        <p-table
          ref="table"
          :columns="columns"
          :sourceData="getDataApi"
          :scorll="{ y: 0 }"
          extraHeight="433"
          :row-selection="rowSelection"
          @change="handleTableChange"
        >
          <template slot="operation" slot-scope="text, record">
            <div class="operation_btn" @click="upload(record)">上传工资条</div>
            <div class="operation_btn" @click="payHistory(record)">薪资发放历史</div>
          </template>
        </p-table>
      </div>
    </div>
    <a-modal
      v-model="visible"
      width="1000px"
      cancelText="取消"
      okText="确定"
      title="工资条"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
    >
      <div class="modal-tips">
        <div class="tips" style="color: red">
          *工资条需满足【工资发放计算公式】方可上传
        </div>
        <div class="modal-tips-item">
          <span style="color: red">*</span>工资发放计算公式：工资发放金额 *
          平台手续费（6.72%）>= 企业余额
        </div>
        <div class="modal-tips-content">
          <div class="modal-tips-item">本次工资发放工资总金额：{{ currentTotal }}元</div>
          <div class="modal-tips-line"></div>
          <div class="modal-tips-item">
            成功发放工资所需企业余额（含平台手续费）：{{ enterTotal }}元
          </div>
        </div>
        <div class="modal-tips-content">
          <div class="modal-tips-item">当前企业余额：{{ enterpriseMoney }}元</div>
          <div class="modal-tips-line"></div>
          <div class="modal-tips-item">
            还需充值：<span style="color: #40a9ff">{{ payNumber }}</span
            >元即可成功发薪
            <a-button
              style="margin-left: 12px"
              size="small"
              type="primary"
              @click="handleToPay"
            >立即充值</a-button
            >
          </div>
        </div>
      </div>
      <div class="content_tips">
        结算周期：
        <a-range-picker
          v-model="date"
          :disabledDate="disabledDate"
          value-format="YYYY-MM-DD"
        />
      </div>
      <a-table
        class="table--layout"
        style="margin-top: 15px"
        :columns="columns1"
        :data-source="modelData"
        :pagination="false"
        :scroll="{ y: 300 }"
      >
        <template
          v-for="col in [
            'day',
            'payable',
            'deduction',
            'workOvertime',
            'workOvertimePayment',
          ]"
          :slot="col"
          slot-scope="text, record, index"
        >
          <div :key="col">
            <a-input-number
              :precision="col === 'day' ? 1 : 2"
              :value="text"
              :min="0"
              @change="(value) => handleChange(value, record.idList, col)"
            />
          </div>
        </template>
      </a-table>
    </a-modal>
    <a-modal
      v-model="history"
      width="1200px"
      cancelText="取消"
      okText="确定"
      title="薪资发放历史"
      :footer="null"
      @ok="handleOk1"
    >
      <div class="historyTable">
        <span>员工姓名: {{ this.historyTable.realName }}</span>
        <span>注册账户id: {{ this.historyTable.userName }}</span>
        <span>联系电话: {{ this.historyTable.phone }}</span>
      </div>
      <p-table
        ref="history"
        row-key="index"
        :scroll="{ y: 300 }"
        extraHeight="700"
        :sourceData="getDataApi1"
        :columns="columns2"
      ></p-table>
      <div class="model_table"></div>
    </a-modal>
    <salyModal ref="salyModal" @success="handleGetData" />
  </div>
</template>

<script>
import {
  addCommission,
  employmentEmployDepartment,
  enterpriseEmploymentManagement,
  enterpriseEmploymentPosition,
  getCommissionList,
  queryHistory,
  addBatchCommission,
  exportSalaryTemplate,
  importByExcel1
} from '@/api/recruitUse'
import salyModal from '@/views/flexibileStaffing/Fcommission/saly-modal.vue'
import { queryBalance } from '@/api/balance'

import moment from 'moment'

var columns1 = [
  {
    title: '姓名',
    dataIndex: 'realName',
    width: 100
  },
  {
    title: '出勤（天）',
    dataIndex: 'day',
    scopedSlots: { customRender: 'day' }
  },
  {
    title: '应发（元）',
    dataIndex: 'payable',
    scopedSlots: { customRender: 'payable' }
  },
  {
    title: '加班时间（小时）',
    dataIndex: 'workOvertime',
    scopedSlots: { customRender: 'workOvertime' }
  },
  {
    title: '加班费用（元）',
    dataIndex: 'workOvertimePayment',
    scopedSlots: { customRender: 'workOvertimePayment' }
  },
  {
    title: '扣款（元）',
    dataIndex: 'deduction',
    scopedSlots: { customRender: 'deduction' }
  },
  {
    title: '实发（元）',
    dataIndex: 'realPay',
    scopedSlots: { customRender: 'realPay' }
  }
]

var columns2 = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (text, record, index) => index + 1
  },
  {
    title: '薪资发放周期',
    dataIndex: 'date',
    width: 200,
    scopedSlots: { customRender: 'date' }
  },
  {
    title: '出勤（天）',
    dataIndex: 'day',
    scopedSlots: { customRender: 'day' }
  },
  {
    title: '应发（元）',
    dataIndex: 'payable',
    scopedSlots: { customRender: 'payable' }
  },
  {
    title: '加班时间（小时）',
    dataIndex: 'workOvertime',
    scopedSlots: { customRender: 'workOvertime' }
  },
  {
    title: '加班费用（元）',
    dataIndex: 'workOvertimePayment',
    scopedSlots: { customRender: 'workOvertimePayment' }
  },
  {
    title: '扣款（元）',
    dataIndex: 'deduction',
    scopedSlots: { customRender: 'deduction' }
  },
  {
    title: '实发（元）',
    dataIndex: 'realPay',
    scopedSlots: { customRender: 'realPay' }
  },
  {
    title: '上传人',
    dataIndex: 'createBy'
  },
  {
    title: '上传时间',
    dataIndex: 'createTime',
    width: '180px'
  }
]
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (text, record, index) => index + 1
  },
  {
    title: '姓名',
    dataIndex: 'realName'
  },
  {
    title: '注册账户ID',
    dataIndex: 'userName'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    customRender: (text) => (text == 0 ? '男' : '女')
  },
  {
    title: '年龄',
    dataIndex: 'age'
  },
  {
    title: '联系电话',
    dataIndex: 'phone'
  },
  {
    title: '岗位薪资',
    dataIndex: 'salary'
  },
  {
    title: '工种/专业',
    dataIndex: 'positionName'
  },
  {
    title: '上一次结算周期',
    width: 240,
    dataIndex: 'date'
  },
  // {
  //   title: '发薪状态',
  //   dataIndex: 'status',
  //   customRender: text => (text === 0 ? '未发放' : '已发放')
  // },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 240,
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'Index',
  components: {
    salyModal
  },
  data () {
    return {
      loading: false,
      columns,
      columns1,
      columns2,
      visible: false,
      history: false,
      projectDepartmentId: undefined,
      positionId: undefined,
      projectId: undefined,
      realName: '',
      phone: '',
      date: [],
      Departmentlist: [],
      Projectlist: [],
      Positionlist: [],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
      modelData: [],
      userName: '',
      historyTable: [],
      confirmLoading: false,
      num: '',
      enterpriseMoney: 0
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status == 1
          }
        })
      }
    },
    currentTotal () {
      const total = this.modelData.reduce((total, item) => {
        return this.accAdd(total, item.realPay || 0)
      }, 0)
      return Math.ceil(total * 100) / 100
    },
    enterTotal () {
      return Math.ceil(this.accMul(this.currentTotal, 1.0672) * 100) / 100
    },
    payNumber () {
      if (this.enterpriseMoney < this.enterTotal) {
        return this.accSubtr(this.enterTotal, Number(this.enterpriseMoney))
      }
      return 0
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      employmentEmployDepartment()
        .then((res) => {
          res.data.forEach((v) => {
            v.label = v.projectDepartment
            v.value = v.projectDepartmentId
          })
          this.Departmentlist = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    disabledDate (current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    changePosition (val) {
      this.positionId = val
    },
    handleTableChange () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    getDataApi (params) {
      return getCommissionList({
        ...params,
        projectDepartmentId: this.projectDepartmentId,
        projectId: this.projectId,
        demandId: this.positionId,
        realName: this.realName,
        phone: this.phone
      }).then((res) => {
        return Promise.resolve({
          ...res,
          data: res.data.map((item) => ({
            ...item,
            id: item.userId + item.demandId
          }))
        })
      })
    },
    handleReset () {
      this.projectDepartmentId = undefined
      this.projectId = undefined
      this.positionId = undefined
      this.demandId = undefined
      this.realName = undefined
      this.phone = undefined
      this.Projectlist = []
      this.Positionlist = []
    },
    handleGetData () {
      this.tableData = []
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.table.initPage()
    },
    // 根据项目名称获取工种/专业
    changeProjectlist (val) {
      this.positionId = undefined
      this.Positionlist = []
      if (val) {
        const params = {
          projectId: val
        }
        enterpriseEmploymentPosition(params)
          .then((res) => {
            res.data.forEach((v) => {
              v.label = v.position
              v.value = v.demandId
            })
            this.Positionlist = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 获取项目名称
    changeDepartment (val) {
      this.projectId = undefined
      this.positionId = undefined
      this.Projectlist = []
      this.Positionlist = []
      if (val) {
        const params = {
          projectDepartmentId: val
        }
        enterpriseEmploymentManagement(params)
          .then((res) => {
            res.data.forEach((v) => {
              v.label = v.projectName
              v.value = v.projectId
            })
            this.Projectlist = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    upload (row) {
      this.visible = true
      this.date = []
      queryBalance({
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
      }).then((res) => {
        this.enterpriseMoney = res?.data?.money || '0.00'
      })
      this.modelData = [
        {
          realName: row.realName,
          demandId: row.demandId,
          userId: row.userId
        }
      ]
    },
    // 薪资发放详情
    payHistory (row) {
      this.history = true
      this.historyTable.realName = row.realName
      this.historyTable.phone = row.phone
      this.historyTable.userName = row.userName
      this.historyTable.demandId = row.demandId
      this.historyTable.userId = row.userId
      this.userId = row.userId
      this.tableData = []
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.history.initPage()
    },
    getDataApi1 (params) {
      return queryHistory({
        ...params,
        demandId: this.historyTable.demandId,
        userId: this.historyTable.userId
      }).then((res) => {
        return Promise.resolve({
          ...res,
          data: res.data.map((item) => ({
            ...item,
            id: item.userId + item.demandId
          }))
        })
      })
    },
    handleAddAll () {
      this.date = []
      queryBalance({
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
      }).then((res) => {
        this.enterpriseMoney = res?.data?.money || '0.00'
      })
      this.modelData = this.selectedRows.map((item) => ({
        idList: item.id,
        realName: item.realName,
        demandId: item.demandId,
        userId: item.userId
      }))
      this.visible = true
    },
    handleOk () {
      if (!this.date || !this.date.length) {
        this.$message.warning('请输入结算周期')
        return
      }
      const result = this.handleResult()
      console.log(result)
      const resultMessage = result.result.join(',')
      const requiredMessage = result.required.join(',')
      if (resultMessage || requiredMessage) {
        this.$message.warning({
          content: (h) => (
            <span>
              {requiredMessage && <span>【{requiredMessage}】工资数据请填写完整</span>}
              {resultMessage && (
                <span>【{resultMessage}】工资数据应发（元）- 扣款（元）≠ 实发（元）</span>
              )}
            </span>
          )
        })
      } else {
        const submitFun = this.modelData.length > 1 ? addBatchCommission : addCommission
        const model =
          this.modelData.length > 1
            ? this.modelData.map((item) => ({
                ...item,
                date: this.date.join('-')
              }))
            : {
                ...this.modelData[0],
                date: this.date.join('-')
              }
        this.confirmLoading = true
        submitFun(model)
          .then(() => {
            this.$message.success('上传成功')
            this.visible = false
            this.modelData = []
            this.selectedRowKeys = []
            this.selectedRows = []
            this.realName = ''
            this.phone = ''
            this.$refs.table.refresh()
          })
          .finally(() => {
            this.confirmLoading = false
          })
      }
    },
    handleOk1 () {
      this.history = false
    },
    handleResult () {
      return this.modelData.reduce(
        (result, item, arr, index) => {
          const {
            day,
            payable,
            deduction,
            realPay,
            workOvertime,
            workOvertimePayment,
            realName
          } = item
          const isEmpty = (number) => ![undefined, null, ''].includes(number)
          if (
            isEmpty(day) &&
            isEmpty(payable) &&
            isEmpty(deduction) &&
            isEmpty(workOvertime) &&
            isEmpty(workOvertimePayment) &&
            isEmpty(realPay)
          ) {
            if (
              realPay ==
              this.accAdd(
                this.accSubtr(payable || 0, deduction || 0),
                workOvertimePayment || 0
              )
            ) {
              return result
            }
            result.result.push(realName)
            return result
          }
          result.required.push(realName)
          return result
        },
        { required: [], result: [] }
      )
    },
    handleChange (value, key, column) {
      const newData = [...this.modelData]
      const target = newData.filter((item) => key === item.idList)[0]
      if (target) {
        target[column] = value
        if (
          ['payable', 'deduction', 'workOvertime', 'workOvertimePayment'].includes(column)
        ) {
          target.realPay = this.accAdd(
            this.accSubtr(target.payable || 0, target.deduction || 0),
            target.workOvertimePayment || 0
          )
        }
        this.modelData = newData
      }
    },

    handleDownLoad () {
      const { current: pageIndex, pageSize } = this.$refs.table.pagination
      exportSalaryTemplate({
        pageIndex,
        pageSize,
        projectDepartmentId: this.projectDepartmentId,
        projectId: this.projectId,
        demandId: this.positionId,
        realName: this.realName,
        phone: this.phone,
        flag: 1
      }).then((res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        })
        const a = document.createElement('a')
        const URL = window.URL || window.webkitURL
        const href = URL.createObjectURL(blob)
        a.href = href
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(href)
      })
    },
    customRequest (file) {
      this.$spin.show()
      const form = new FormData()
      form.append('file', file.file)
      importByExcel1(form)
        .then((res) => {
          this.$refs.salyModal.open(res.data)
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleToPay () {
      window.open('/home/balance?isRecharge=true')
    }
  }
}
</script>

<style lang="less">
@import url("../../../common/style");

.commission_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .search_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 31px;

    .item {
      display: flex;
      align-items: center;
      flex: 1;

      .label {
        margin-right: 20px;
        width: 65px;
      }

      .input__content {
        width: calc(100% - 100px);
      }
    }

    .item__content {
      width: 25% !important;
      display: flex;
      align-items: center;

      .label {
        margin-right: 20px;
        width: 65px;
      }

      .input__content {
        width: calc(100% - 100px);
      }
    }

    .last_item {
      justify-content: flex-end;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .btn_box {
    display: flex;
    height: 31px;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .operation_btn {
    display: inline-block;
    margin-left: 8px;
  }
}

.historyTable {
  margin-bottom: 24px;

  span {
    font-size: 18px;
    display: inline-block;
    margin-right: 100px;
  }
}

.model_table {
  height: 40px;
}
</style>
